import Vue from 'vue'

// axios
import axios from 'axios'

// WebAPI
const axiosIns = axios.create({
  // baseURL: 'http://localhost:52340/api/', // Development...
  baseURL: 'https://monium-wa.matrimonium.ch/api/', // Server, resp. production...
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
