// import axios from '@axios'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// async function getTrans(sprache) {
//   try {
//     const r = await axios.get('sproc/gettrans', {
//       params: {
//         sprache,
//       },
//     })
//     console.log(r)
//     return r
//   } catch (error) {
//     console.log(error)
//   }
//   return null
// }

// function loadLocaleMessages() {
//   const messages = {}
//   getTrans('de')
//     .then(resDE => {
//       const locDE = 'de'
//       messages[locDE] = resDE.data[0].DE
//       console.log(messages)
//       getTrans('fr')
//         .then(resFR => {
//           const locFR = 'fr'
//           messages[locFR] = resFR.data[0].FR
//           console.log(messages)
//           return messages
//         }).catch(err => err)
//     }).catch(err => err)
// }

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  // messages: loadLocaleMessages(),
  // messages: {},
})
