import axios from '@axios'

export default {
  // kon: getInfo().then(res => res).catch(err => err),
  kon: 'KON',
  test: 'global',
  async getInfo(sprache) {
    try {
      const r = await axios.get('sproc/getinfo', {
        params: {
          sprache,
          delay: 1,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getKonfig(benid) {
    try {
      const r = await axios.get('sproc/getKonfig', {
        params: {
          benid,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getTrans(sprache) {
    try {
      const r = await axios.get('sproc/gettrans', {
        params: {
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
}
