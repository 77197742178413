export default [
  {
    path: '/start/',
    name: 'start',
    component: () => import('@/views/start/Start.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'berater',
      action: 'read',
    },
  },
  {
    path: '/fragebogen/',
    name: 'fragebogen',
    component: () => import('@/views/fragebogen/Fragebogen.vue'),
    meta: {
      resource: 'klienterw',
      action: 'read',
    },
  },
  {
    path: '/grunddaten/',
    name: 'grunddaten',
    component: () => import('@/views/grunddaten/Grunddaten.vue'),
    meta: {
      resource: 'berater',
      action: 'read',
    },
  },
  {
    path: '/mittel/',
    name: 'mittel',
    component: () => import('@/views/mittel/Mittel.vue'),
    meta: {
      resource: 'berateruh',
      action: 'read',
    },
  },
  {
    path: '/bedarf/',
    name: 'bedarf',
    component: () => import('@/views/bedarf/Bedarf.vue'),
    meta: {
      resource: 'berateruh',
      action: 'read',
    },
  },
  {
    path: '/unterhalt/',
    name: 'unterhalt',
    component: () => import('@/views/unterhalt/Unterhalt.vue'),
    meta: {
      resource: 'berateruh',
      action: 'read',
    },
  },
  {
    path: '/gueterrecht/',
    name: 'gueterrecht',
    component: () => import('@/views/gueterrecht/Gueterrecht.vue'),
    meta: {
      resource: 'beraterga',
      action: 'read',
    },
  },
  {
    path: '/vorsorge/',
    name: 'vorsorge',
    component: () => import('@/views/vorsorge/Vorsorge.vue'),
    meta: {
      resource: 'beraterbvg',
      action: 'read',
    },
  },
  {
    path: '/vereinbarung/',
    name: 'vereinbarung',
    component: () => import('@/views/vereinbarung/Vereinbarung.vue'),
    meta: {
      resource: 'berater',
      action: 'read',
    },
  },
  {
    path: '/belege/',
    name: 'belege',
    component: () => import('@/views/belege/Belege.vue'),
    meta: {
      resource: 'klienterw',
      action: 'read',
    },
  },
  {
    path: '/korrespondenz/',
    name: 'korrespondenz',
    component: () => import('@/views/korrespondenz/Korrespondenz.vue'),
    meta: {
      resource: 'beratererw',
      action: 'read',
    },
  },
  {
    path: '/protokoll/',
    name: 'protokoll',
    component: () => import('@/views/protokoll/Protokoll.vue'),
    meta: {
      resource: 'beratererw',
      action: 'read',
    },
  },
  {
    path: '/honorar/',
    name: 'honorar',
    component: () => import('@/views/honorar/Honorar.vue'),
    meta: {
      resource: 'beratererw',
      action: 'read',
    },
  },
  {
    path: '/justitia/',
    name: 'justitia',
    component: () => import('@/views/justitia/justitia.vue'),
    meta: {
      resource: 'berater',
      action: 'read',
    },
  },
  {
    path: '/mandant/',
    name: 'mandant',
    component: () => import('@/views/mandant/Mandant.vue'),
    meta: {
      resource: 'mandant',
      action: 'read',
    },
  },
  {
    path: '/abrechnung/',
    name: 'abrechnung',
    component: () => import('@/views/abrechnung/Abrechnung.vue'),
    meta: {
      resource: 'mandant',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/kalender/',
    name: 'kalender',
    component: () => import('@/views/kalender/Kalender.vue'),
    meta: {
      resource: 'berater',
      action: 'read',
    },
  },
  {
    path: '/chatbot/',
    name: 'chatbot',
    component: () => import('@/views/chatbot/chatbot.vue'),
    meta: {
      resource: 'berater',
      action: 'read',
    },
  },
  {
    path: '/benutzer/',
    name: 'benutzer',
    component: () => import('@/views/benutzer/benutzer.vue'),
    meta: {
      resource: 'klient',
      action: 'read',
    },
  },
  {
    path: '/adminabrechnung/',
    name: 'adminabrechnung',
    component: () => import('@/views/adminabrechnung/AdminAbrechnung.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
  {
    path: '/vorlagen/',
    name: 'vorlagen',
    component: () => import('@/views/vorlagen/Vorlagen.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
  {
    path: '/uebersetzung/',
    name: 'uebersetzung',
    component: () => import('@/views/uebersetzung/Uebersetzung.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/views/misc/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/misc/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/views/misc/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/misc/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/views/misc/error',
    name: 'misc-error',
    component: () => import('@/views/error/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
]
