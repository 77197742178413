import Vue from 'vue'

import { registerLicense } from '@syncfusion/ej2-base'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import InputFacade from 'vue-input-facade'

import g from '@/service/global'
import useJwt from '@/auth/jwt/useJwt'
import dos from '@/service/dossier'

import { logout, setRefresh, getRefresh, setUserID, getUserID, setPwd, getPwd, setCurrRoute, getCurrRoute } from '@/auth/utils' // eslint-disable-line

import _ from 'lodash'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
// import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Input facade...
Vue.use(InputFacade)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$_ = _  // eslint-disable-line

Vue.prototype.$win = window

Vue.prototype.$eventBus = new Vue()

const gKon = {}
Vue.prototype.$g = gKon
gKon.Test = g.kon
// gKon.Val = g.test

// Registering Syncfusion license key - Version 20.4.51..
registerLicense('Mgo+DSMBaFt/QHRqVVhlXVpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS3xVdkNgUH9fdHdRTw==;Mgo+DSMBPh8sVXJ0S0J+XE9Bd1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdEdlWXldeXRUQmVcWA==;ORg4AjUWIQA/Gnt2VVhkQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhX39Yc31VR2RaV00=;MTIwNzY2MkAzMjMwMmUzNDJlMzBhQ3RVUHB4dG11YkNCTWUvNzc4Z3N3OUtDeVZURlo1N3pSOHVNaHV2bjhvPQ==;MTIwNzY2M0AzMjMwMmUzNDJlMzBhWDlxWCthTko3KzVRNEV4OEpMOU1rR2Y1WE9hUmlHSzJXSU5qVUNqSzlVPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpDVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhW3ledndcRmFZVUd/;MTIwNzY2NUAzMjMwMmUzNDJlMzBDV1h3R09wMnY1bnNqLzZ2OStEajJhQW5vVUlnUUloQ0dXTWUrY0ZFM0pNPQ==;MTIwNzY2NkAzMjMwMmUzNDJlMzBTbWFWUThRR1h3MnJtekRBQTRpbDE0K1Mrd2IrTEtUY2dYS3lpU3Zxa3FJPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhX39Yc31VR2ZeUkQ=;MTIwNzY2OEAzMjMwMmUzNDJlMzBTamt3SndDQVYwSEtQVlNOTDE4ajYwNHliSUFSMXBVM05XOHJFUXNsbEpnPQ==;MTIwNzY2OUAzMjMwMmUzNDJlMzBVUUhUcDVDZ3FCZGZrS2dYVnVoeWp4MWFwZ3pSLzc0TFMzRHUyVjMwVkN3PQ==;MTIwNzY3MEAzMjMwMmUzNDJlMzBDV1h3R09wMnY1bnNqLzZ2OStEajJhQW5vVUlnUUloQ0dXTWUrY0ZFM0pNPQ==')
// Registering Syncfusion license key - Version 21.x.xx..
// registerLicense('MzExNDE3OEAzMjMxMmUzMDJlMzBYL1FjeGdyeXlIQVEyYUExNkZZaitKZ09XSkYyWXNqY3FheXVPaVRkL0trPQ==;MzExNDE3OUAzMjMxMmUzMDJlMzBDcytPMFhzZ1FuYUxqQ0NrTzJIQmRkcXZzOEpsaXVDV3NnR2hEaWRHS3o0PQ==;Mgo+DSMBaFt+QHJqVEZrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRbQl9iQH5UdUdhXndWeHw=;Mgo+DSMBPh8sVXJ1S0R+XVFPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSXxSf0VkWn1cd31VR2g=;ORg4AjUWIQA/Gnt2VFhiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Rd0xjXnxccnNcRmVU;NRAiBiAaIQQuGjN/V0d+Xk9FdlRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTdERqWXhdcnZST2JdVQ==;MzExNDE4NEAzMjMxMmUzMDJlMzBnOHE1cXpmeVpxVG1pTlNaMWRvM0Z0SlQxci9pbndMM25pRUJGeGxiZEhNPQ==;MzExNDE4NUAzMjMxMmUzMDJlMzBITFlqT2NHbWIwT2lxSWs5YzBacjk2S2dBeHJlT3NQSkV4MHdCeWR5MHhnPQ==;Mgo+DSMBMAY9C3t2VFhiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Rd0xjXnxccnxVQGNd;MzExNDE4N0AzMjMxMmUzMDJlMzBWOHFOaXhMeWFvMHZmc0VkaTUrSFo3Q1VUT1FIVENZR2JUd2E0QnFUOUVNPQ==;MzExNDE4OEAzMjMxMmUzMDJlMzBjaUx0cHR6VDRET3ZLaG1uV2RpeHozamRMSGlnMitRVjBaMVhpUXlyNEVNPQ==;MzExNDE4OUAzMjMxMmUzMDJlMzBnOHE1cXpmeVpxVG1pTlNaMWRvM0Z0SlQxci9pbndMM25pRUJGeGxiZEhNPQ==')
new Vue({
  data: {
    currDosID: 0,
    currDosArt: -1,
    currDosBez: '',
    pdfAPIReady: false,
  },
  router,
  store,
  i18n,
  watch: {
    pdfAPIReady(val) {
      this.pdfAPIReady = val
    },
  },
  async created() {
    this.$g.browserLocale = (navigator.languages !== undefined ? navigator.languages[0] : navigator.language).includes('fr') ? 'fr' : 'de'
    this.$g.isRefresh = false
    this.$g.isi18n = false
    window.hasFormDataChanged = false
    window.bvModal = this.$bvModal
    window.saveFormData = null
    window.checkSave = null
    window.isFormValid = true
    window.formInvalid = this.$root.msgBox

    const tde = await g.getTrans('de')
    if (tde.data !== null && tde.data.length > 0) this.$i18n.setLocaleMessage('de', JSON.parse(tde.data[0].DE))

    this.$g.isi18n = true

    if (window.AdobeDC) this.pdfAPIReady = true

    window.addEventListener('beforeunload', this.navigateAway)

    if (getRefresh() === '1') {
      this.$g.isRefresh = true
      await this.login(getUserID(), getPwd(), '')
      this.$router.replace(getCurrRoute())
    }
  },
  mounted() {
    this.adobeDC = document.createElement('script')
    this.adobeDC.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/viewer.js')
    document.head.appendChild(this.adobeDC)
    document.addEventListener('adobe_dc_view_sdk.ready', () => { this.$root.pdfAPIReady = true })
  },
  methods: {
    navigateAway: function navigateAway(event) { // eslint-disable-line
      // console.log('Navigate away....') // Called when user leaves/closes separis...
      // event.returnValue = '' // eslint-disable-line  // Possibility to check by user, whether he will refresh the current page...
      logout() // remove localstore userData => thus redirct start screen to login...
      setCurrRoute(this.$route.path)
      setRefresh('1') // IMPORTANT: Refresh handled by cookie!!!
      this.$g.isRefresh = true
    },
    async login(userID, pwd, code) {
      setUserID(userID)
      setPwd(pwd)
      const r = await useJwt.login(userID, pwd, code)
      if (r.result === 200 && r.data.length === 1 && r.data[0].CodeOK) {
        const userData = r.data[0]
        userData.role = userData.Rolle
        userData.Sprache = userData.BenSprache
        this.$g.user = userData
        this.$i18n.locale = userData.BenSprache
        // if (userData.Ist2FA && !userData.CodeOK) return
        if (!userData.Status) {
          this.benutzerInaktiv = true
          return
        }

        const info = await g.getInfo(userData.BenSprache)
        if (info.data.length > 0) this.$g.info = info.data

        // Initializing the $root variables for global access, for instance this.$root.currDosID...
        const d = await dos.getDossier(userData.CurrDosID)
        this.$root.currMandantID = d !== null && d.data.length === 1 ? d.data[0].MandatID : userData.MandantID
        this.currDosID = d !== null && d.data.length === 1 ? d.data[0].DosID : 0
        this.currDosArt = d !== null && d.data.length === 1 ? d.data[0].Art : -1
        this.currDosBez = d !== null && d.data.length === 1 ? (this.currDosArt === 0 ? 'Trennung ' : this.currDosArt === 1 ? 'Scheidung ' : 'Elternvereinbarung ') + d.data[0].Bezeichnung : '' // eslint-disable-line
        this.AnzahlKinder = d !== null && d.data.length === 1 ? d.data[0].AnzahlKinder : 0
        this.MitKind = d !== null && d.data.length === 1 ? d.data[0].MitKind : false
        this.$root.MitKind = this.MitKind
        this.MitGA = d !== null && d.data.length === 1 ? d.data[0].MitGa : false
        this.MitErweiterung = d !== null && d.data.length === 1 ? d.data[0].MitErweiterung : false
        this.VNameFrau = d !== null && d.data.length === 1 ? d.data[0].VNameFrau : 'Partnerin'
        this.VNameMann = d !== null && d.data.length === 1 ? d.data[0].VNameMann : 'Partner'

        // konfig has got to be loaded when dossier exists and when changing...
        // const konfig = await global.getKonfig(userData.BenID)
        // if (konfig.data.length > 0) this.$g.konfig = JSON.parse(JSON.stringify(konfig.data[0]))

        const tde = await g.getTrans('de')
        if (tde.data.length > 0) this.$i18n.setLocaleMessage('de', JSON.parse(tde.data[0].DE))
        const tfr = await g.getTrans('fr')
        if (tfr.data.length > 0) this.$i18n.setLocaleMessage('fr', JSON.parse(tfr.data[0].FR))

        this.$g.isi18n = true

        localStorage.setItem('userData', JSON.stringify(userData))
        this.$ability.update(JSON.parse(userData.Ability))
      } else localStorage.removeItem('userData')
    },
    async msgBox(msg) {
      return await this.$bvModal.msgBoxOk(msg).then(value => { return value }) // eslint-disable-line
    },
    async msgBoxConfirm(msg) {
      const ok = await this.$bvModal.msgBoxConfirm(msg, {
        cancelVariant: 'outline-secondary', noCloseOnBackdrop: true, centered: true, cancelTitle: 'Abbrechen',
      }).then(value => { return value }) // eslint-disable-line
      return ok
    },
    async checkSave() {
      return this.$root.msgBoxConfirm('Änderungen speichern?')
    },
    showToast(titel, msg) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: titel,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: msg,
          },
        },
        { position: 'bottom-right' },
      )
    },
  },
  render: h => h(App),
}).$mount('#app')
