import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

// export const getAbilities = () => this.$ability

// export const updateUserAbilities = userData => {
//   this.$ability.update(userData.Ability)
// }

export const _ = undefined
