import Vue from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import VueCookies from 'vue-cookies'
import CryptoJS from 'crypto-js'

Vue.use(VueCookies)

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') !== null && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName) !== null
  // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'berater') return { name: 'access-control' }
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
  // if (userRole !== '') return { name: 'auth-login' }
  // return { name: 'auth-login' }
}

export function setRefresh(refresh) {
  Vue.$cookies.set('isRefresh', refresh, '2s') // expires after 2 seconds...
}
export function getRefresh() {
  return Vue.$cookies.get('isRefresh')
}
export function setCurrRoute(currRoute) {
  Vue.$cookies.set('currRoute', currRoute, 0) // expires end of session...
}
export function getCurrRoute() {
  return Vue.$cookies.get('currRoute')
}

export function setUserID(userID) {
  Vue.$cookies.set('userID', userID, 0) // expires end of session...
}
export function getUserID() {
  return Vue.$cookies.get('userID')
}
export function setPwd(pwd) {
  const encPwd = CryptoJS.AES.encrypt(pwd, '_cipher$Key').toString()
  Vue.$cookies.set('userPwd', encPwd, 0) // expires end of session...
}
export function getPwd() {
  const bytes = CryptoJS.AES.decrypt(Vue.$cookies.get('userPwd'), '_cipher$Key')
  return bytes.toString(CryptoJS.enc.Utf8)
}

export function logout() {
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
  localStorage.removeItem('userData')
}
