import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// import { canNavigate, updateUserAbilities } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, setRefresh, getRefresh } from '@/auth/utils' // eslint-disable-line
import views from './routes/views'

Vue.use(VueRouter)
Vue.use(VueCookies)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'start' } },
    // { path: '/views/misc/not-authorized', redirect: { name: 'auth-login' } }, // Hack to redirect to login at app start, thus misc-not-authorized will never be used...
    ...views,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // console.log(canNavigate(to))
  // console.log(isLoggedIn)
  // console.log(getRefresh())
  // console.log(to.meta.redirectIfLoggedIn)
  if (window.saveFormData != null) {
    // console.log(window.isFormValid)
    if (!window.isFormValid) {
      await window.formInvalid('Das Formular enthält Fehler und kann nicht verlassen werden!')
      return next(false)
    }
    if (window.checkSave != null) {
      if (await window.checkSave()) {
        await window.saveFormData(false)
      } else return next(false)
    }
    window.isFormValid = true
    window.saveFormData = null
    window.checkSave = null
  }
  // Following code works - problem: isDirty, resp. window.hasFormDataChanged cannot be always set appropriatally - thus window.saveFormData is used to automatically save data when needed...
  // if (window.hasFormDataChanged) {
  //   if (await window.bvModal.msgBoxConfirm('Das Formular hat Änderungen, wollen Sie das Formular ohne Speichern verlassen?',
  //     { cancelVariant: 'outline-secondary', noCloseOnBackdrop: true, centered: true, cancelTitle: 'Abbrechen' })) { // eslint-disable-line
  //     window.hasFormDataChanged = false
  //   } else return next(false)
  // }

  if (!isLoggedIn && to.path !== '/login' && to.path !== '/forgot-password') return next({ name: 'auth-login' })

  if (!canNavigate(to)) return next({ name: 'misc-not-authorized' })

  return next()

  // if (to.meta.redirectIfLoggedIn) {
  //   const userData = getUserData()
  //   return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  // IMPORTANT: Entrypoint for the navigation at the very beginning ist in Login.vue...
  // if (!canNavigate(to)) {
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   return next({ name: 'misc-not-authorized' })
  // }

  // // Redirect if logged in => does't work at the moment...
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   console.log('router home...')
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  // return next()
})

// router.afterEach((to, from) => {
//   console.log('from afterEach: ')
//   console.log(from)
//   console.log('to afterEach: ')
//   console.log(to)
// })

export default router
