import axios from '@axios'

export default {
  async getDossiers(mandantID, benID) {
    try {
      const r = await axios.get('dossiers/getDossiers', {
        params: {
          mandantID,
          benID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossier(dosID) {
    try {
      const r = await axios.get('dossiers/getDossier', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossier(data) {
    const d = JSON.parse(JSON.stringify(data))
    try {
      d.AnspruchElternID = d.AnspruchElternID ? 2 : 1
      d.WerWohnungID = d.WerWohnungID ? 2 : 1
      d.WohnKindPauschal = (d.WohnKindPauschal ?? '0').toString().replaceAll(/[’']/g, '')
      d.SparquoteManuellUP = (d.SparquoteManuellUP ?? '0').toString().replaceAll(/[’']/g, '')
      d.SparquoteManuellUB = (d.SparquoteManuellUB ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await axios.get('dossiers/getDossierUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierWorkflow(dosID) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflow', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierWorkflowDropDown(dosID) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowDropDown', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierWorkflowTask(taskID) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowTask', {
        params: {
          taskID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierWorkflowTask(data) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowUpdate', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierWorkflowTaskNew(data) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowTaskInsert', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierWorkflowTaskDel(taskID) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowDelete', {
        params: {
          taskID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierWorkflowTaskCompleted(taskID) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowTaskCompleted', {
        params: {
          taskID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierWorkflowSeq(dosID, von, nach) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowSeqUpdate', {
        params: {
          dosID,
          von,
          nach,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierWorkflowVorlage(mandantID) {
    try {
      const r = await axios.get('dossiers/getDossierWorkflowVorlage', {
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierKosten(dosID, sprache) {
    try {
      const r = await axios.get('dossiers/getDossierKosten', {
        params: {
          dosID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierKosten(data, benID, mandantID, sprache) {
    try {
      const r = await axios.get('dossiers/GetDossierKostenInsUpd', {
        params: {
          data: JSON.stringify(data),
          benID,
          mandantID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async postDossierVollmacht(datei, dosID) {
    const formData = new FormData()
    try {
      formData.append('vollmacht', datei)
      const r = await axios.post('dossiers/postDossierVollmacht', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getDossierVollmacht(dosID) { // Download...
    try {
      const r = await axios.get('dossiers/getDossierVollmacht', {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },

}
