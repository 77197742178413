import jwt from 'jsonwebtoken'
import jwtDefaultConfig from './jwtDefaultConfig'

// ! These two secrets shall be in .env file and not in any other file
const jwtConfigEnv = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m',
}

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  async login(userID, password, code) {
    let error = {
      email: ['Something went wrong'],
    }
    try {
      const r = await this.axiosIns.get('benutzers/getlogin', {
        params: {
          email: userID,
          pwd: password,
          code,
        },
      })
      // if (r.data.length === 1) this.$router.push('/pages/register').catch(() => {})
      if (r.data.length === 1) {
        try {
          // const accessToken = jwt.sign({ id: r.data[0].BenID }, jwtConfigEnv.secret, { expiresIn: jwtConfigEnv.expireTime })
          const refreshToken = jwt.sign({ id: r.data[0].BenID }, jwtConfigEnv.refreshTokenSecret, {
            expiresIn: jwtConfigEnv.refreshTokenExpireTime,
          })
          this.setToken(r.data[0].token)
          this.setRefreshToken(refreshToken)
          // const userData = { ...user }
          // const data = r.data[0]
          // delete data.password
          const response = {
            result: 200,
            data: r.data,
          }
          return response
        } catch (e) {
          error = e
          const response = {
            result: 400,
            error,
          }
          return response
        }
      } else {
        error = {
          email: ['Email or Password is Invalid'],
        }
        const response = {
          result: 400,
          error,
        }
        return response
      }
    } catch (err) {
      const response = {
        result: 400,
        error: err,
      }
      return response
    }
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
